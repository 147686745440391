.image-container {
  position: relative;
  width: 100%; /* Make the container responsive */
  padding-top: 56.25%; /* This is for a 16:9 aspect ratio (height/width = 9/16 = 0.5625) */
  overflow: hidden; /* Hide overflow */
}

.card-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; /* Cover the entire container */
}
