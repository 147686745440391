/* Padding for the services section to avoid overlap with the fixed navbar */
.services-section {
    margin-top: 80px; /* Ensure section starts below the navbar */
}

/* Section Title and Subtitle */
.section-name {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff; /* Light color for titles */
    text-align: center;
}

.section-subtitle {
    font-size: 1.2rem;
    color: #ccc; /* Light gray for subtitles */
    margin-bottom: 30px;
    text-align: center;
}

/* Service Row Styling */
.service-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.service-row.reverse {
    flex-direction: row-reverse; /* Reverse the order of columns for alternate rows */
}

/* Service Columns */
.service-image-col,
.service-text-col {
    padding: 20px;
    flex: 1; /* Ensure equal width for both columns */
}

/* Service Image Styling */
.service-image-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-image {
    max-width: 100%;           /* Ensure the image fits within its container */
    max-height: 400px;         /* Maintain good proportions */
    object-fit: cover;         /* Ensures the image fills the container without distortion */
    border-radius: 10px;       /* Adds rounded corners */
}

/* Service Box Styling */
.service-box {
    background-color: #1e1e1e; /* Dark gray background for service box */
    border: 1px solid #333; /* Subtle border color */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Darker shadow */
}

.service-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6); /* Slightly stronger shadow on hover */
}

/* Service Title */
.service-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff; /* Light text for service title */
    margin-bottom: 15px;
}

/* Service Description */
.service-description {
    font-size: 1rem;
    color: #bbb; /* Light gray for descriptions */
    line-height: 1.6;
}

/* Responsive Adjustments for Mobile */
@media (max-width: 768px) {
    .services-section {
        margin-top: 60px; /* Adjust margin for mobile if navbar height changes */
    }

    .service-row {
        flex-direction: column; /* Stack columns vertically on smaller screens */
        margin-bottom: 30px;    /* Reduce spacing between rows */
    }

    .service-row.reverse {
        flex-direction: column; /* Stack even reversed rows in the same order */
    }

    .service-image {
        max-width: 100%;        /* Allow images to scale with container width */
        max-height: 300px;      /* Limit height for better proportions on small screens */
    }

    .service-name {
        font-size: 1.25rem;
    }

    .section-name {
        font-size: 2rem;
    }

    .section-subtitle {
        font-size: 1rem;
    }

    .service-description {
        font-size: 0.9rem;
    }
}

/* Global Container and Row Styling */
.container {
    max-width: 1200px;
}

.row {
    margin-bottom: 40px; /* Adds uniform spacing between rows */
}

/* Navbar Dark Theme */
.navbar {
    background-color: #181818; /* Dark background for the navbar */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8); /* Slightly stronger shadow */
    color: #fff; /* Light text for the navbar */
}

/* Additional Fine-Tuning */
html {
    scroll-behavior: smooth; /* Optional: smooth scrolling */
}

body {
    margin: 0;
    padding-top: 80px; /* Ensure content starts after the navbar */
    background-color: #101010; /* Dark background for the whole page */
    color: #fff; /* Light text color for body */
}
